import subTract from '~/assets/images/icons/subtract.svg?file'
import { Loading } from '~/types/helper'

import * as containerStyles from './SubtractBlock.module.scss'

interface SubtractBlockProps {
  image?: string
  loading?: Loading
  isTop?: boolean
  isCustomImg?: boolean
}

const SubtractBlock = ({
  isTop,
  isCustomImg,
  image,
  loading = 'lazy',
}: SubtractBlockProps) => (
  <div
    className={containerStyles[`subtractWrapper__${isTop ? 'top' : 'bottom'}`]}
  >
    {isCustomImg ? (
      <img
        src={image}
        loading={loading}
        alt="Background line | Codica"
        title="Background line"
      />
    ) : (
      <img
        src={subTract}
        loading={loading}
        alt="Background sub line | Codica"
        title="Background sub line"
      />
    )}
  </div>
)

export default SubtractBlock
