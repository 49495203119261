import { ExpertiseSectionMock } from '~/types/mock'

const DesignDeliverables: ExpertiseSectionMock[] = [
  {
    icon: 'sprite3DIconSmall_9',
    title: 'Clickable prototypes',
    description:
      'The prototypes display the project’s structure, logic, and main flows. You can always return to them and add new functionality, considering the existing features.',
  },
  {
    icon: 'sprite3DIconSmall_3',
    title: 'Website visual style',
    description:
      'The UI/UX design team develops a unique visual style for the entire web project. Later, it can be used for marketing purposes.',
  },
  {
    icon: 'sprite3DIconSmall_1',
    title: 'Guideline',
    description:
      'Everything related to the visual style (fonts, buttons, their states, and much more) is included in the guideline that developers use. In the future, the guideline may be supplemented if new functionality appears.',
  },
  {
    icon: 'sprite3DIconSmall_20',
    title: 'Design (mobile and desktop versions)',
    description:
      'The client has access to Figma and all designs. You can leave comments and, if necessary, download designs in any convenient format.',
  },
]

export default DesignDeliverables
