export const SchemaOrganisation = () => {
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Codica',
    mainEntityOfPage: 'https://www.codica.com',
    url: 'https://www.codica.com',
    logo: 'https://codica-images-production.s3.eu-central-1.amazonaws.com/codica_logo_3e7a6581a4.png',
    sameAs: [
      'https://www.facebook.com/CodicaCom/',
      'https://twitter.com/codicacom',
      'https://www.linkedin.com/company/codica-com/',
      'https://github.com/codica2',
      'https://clutch.co/profile/codica',
      'https://medium.com/codica',
      'https://www.behance.net/codica',
      'https://www.instagram.com/codica_com/',
      'https://www.crunchbase.com/organization/codica',
    ],
    email: 'hello@codica.com',
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: '+38 097 778 9031',
      contactType: 'customer support',
    },
    foundingDate: '2015',
    member: {
      '@type': 'Person',
      name: 'Dmytro Chekalin',
      jobTitle: 'CEO',
      nationality: {
        '@type': 'Country',
        name: 'Ukraine',
      },
      url: 'https://www.linkedin.com/in/dmitrychekalin/',
    },
    address: [
      {
        '@type': 'PostalAddress',
        addressLocality: 'Tallinn, Estonia',
        postalCode: '11411',
        streetAddress: 'Majaka 26-102',
      },
    ],
  }

  return JSON.stringify(schema)
}
