import { Link } from 'gatsby'
import { CSSProperties, ReactNode } from 'react'

import * as containerStyles from './Title.module.scss'

interface TitleProps {
  link?: string
  className?: string
  headingLevel?: string
  children?: ReactNode
  style?: CSSProperties
}

const Title = ({
  link,
  className,
  style,
  children,
  headingLevel = 'h3',
}: TitleProps) => {
  const TitleComponent = (props) => {
    const HeadingTag = `${headingLevel}`
    return <HeadingTag {...props}>{props.children}</HeadingTag>
  }

  return (
    <TitleComponent className={className} style={style}>
      {link ? (
        <Link to={link} className={containerStyles.titleComponentInLink}>
          {children}
        </Link>
      ) : (
        children
      )}
    </TitleComponent>
  )
}

export default Title
