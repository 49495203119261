import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image'

type ArticleImageProps = {
  image: IGatsbyImageData | undefined
  alt: string
}

const ArticleImage = ({ alt, image }: ArticleImageProps) => (
  <GatsbyImage
    image={getImage(image as IGatsbyImageData) as IGatsbyImageData}
    alt={`Post: ${alt}`}
    title={alt}
    loading="lazy"
  />
)

export default ArticleImage
