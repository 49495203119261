import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'

import { Item } from '~/types/data-array'
import { cx } from '~/utils'

import * as containerStyles from './ServicesDifferenceBetween.module.scss'

interface ServicesDifferenceBetweenProps {
  titleFirst: string
  listFirst: Item[]
  titleSecond: string
  listSecond: Item[]
}

const ServicesDifferenceBetween = ({
  titleFirst,
  listFirst,
  titleSecond,
  listSecond,
}: ServicesDifferenceBetweenProps) => {
  const query = useStaticQuery(graphql`
    query {
      image: file(
        relativePath: { eq: "services/design/design-difference-between.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
    }
  `)

  const image = getImage(query.image)

  return (
    <div className="container container-md">
      <div
        className={cx(
          'row',
          containerStyles.servicesDifferenceBetweenSection__row,
        )}
      >
        <div className="col-12 col-md-4">
          <div
            className={cx(
              containerStyles.servicesDifferenceBetweenSection__icon,
              containerStyles.servicesDifferenceBetweenSection__icon_first,
            )}
          />
          <div
            className={containerStyles.servicesDifferenceBetweenSection__title}
          >
            {titleFirst}
          </div>
          <ul
            className={containerStyles.servicesDifferenceBetweenSection__list}
          >
            {listFirst.map(({ item }) => (
              <li
                key={String(item)}
                className={
                  containerStyles.servicesDifferenceBetweenSection__listItem
                }
              >
                {item}
              </li>
            ))}
          </ul>
        </div>

        <div
          className={cx(
            'col-12 col-md-4',
            containerStyles.servicesDifferenceBetweenSection__imageWrapper,
          )}
        >
          <div
            className={containerStyles.servicesDifferenceBetweenSection__image}
          >
            <GatsbyImage
              image={image as IGatsbyImageData}
              alt="Difference between UI and UX | Codica"
              title="Difference between UI and UX"
              loading="lazy"
            />
          </div>
        </div>

        <div className="col-12 col-md-4">
          <div
            className={cx(
              containerStyles.servicesDifferenceBetweenSection__icon,
              containerStyles.servicesDifferenceBetweenSection__icon_second,
            )}
          />
          <div
            className={containerStyles.servicesDifferenceBetweenSection__title}
          >
            {titleSecond}
          </div>
          <ul
            className={containerStyles.servicesDifferenceBetweenSection__list}
          >
            {listSecond.map(({ item }) => (
              <li
                key={String(item)}
                className={
                  containerStyles.servicesDifferenceBetweenSection__listItem
                }
              >
                {item}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default ServicesDifferenceBetween
