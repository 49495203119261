// extracted by mini-css-extract-plugin
export var designDevelopmentExpertsSection = "p_bB";
export var designDevelopmentServices = "p_by";
export var designDifferenceBetweenSection = "p_bz";
export var designFeaturedCaseSection = "p_bJ";
export var designFullVideoSection = "p_bD";
export var designInsightsSection = "p_bK";
export var designPhaseSection = "p_bG";
export var designPrimeSection = "p_bx";
export var designProcessSection = "p_bF";
export var designTestimonialPosSection = "p_bC";
export var domainExpertiseDesign = "p_bH";