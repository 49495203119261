import { getImage } from 'gatsby-plugin-image'
import Nav from 'react-bootstrap/Nav'
import Tab from 'react-bootstrap/Tab'

import { fileToImageLikeData } from '~/utils'
import useDesignStaticQuery from '~/views/Services/Design/useDesignStaticQuery'

import DevelopmentServicesSectionItem from './components/DevelopmentServicesSectionItem'

import './DevelopmentServicesSection.scss'

const DevelopmentServicesSection = () => {
  const query = useDesignStaticQuery()

  const designWithUiOne = getImage(fileToImageLikeData(query.designWithUiOne))
  const designWithUiTwo = getImage(fileToImageLikeData(query.designWithUiTwo))
  const designWithUiThree = getImage(
    fileToImageLikeData(query.designWithUiThree),
  )
  const designWithoutUiOne = getImage(
    fileToImageLikeData(query.designWithoutUiOne),
  )
  const designWithoutUiTwo = getImage(
    fileToImageLikeData(query.designWithoutUiTwo),
  )
  const designWithoutUiThree = getImage(
    fileToImageLikeData(query.designWithoutUiThree),
  )

  return (
    <div className="customTabDesignServices">
      <Tab.Container
        id="serviceDesignTabs"
        transition={false}
        defaultActiveKey="withDesign"
      >
        <div className="container container-md">
          <div className="row">
            <div className="col-12">
              <Nav variant="tabs">
                <Nav.Link eventKey="withDesign">with UI/UX team</Nav.Link>
                <Nav.Link eventKey="withoutDesign">without UI/UX team</Nav.Link>
              </Nav>
            </div>
          </div>
        </div>

        <Tab.Content>
          <Tab.Pane eventKey="withDesign">
            <div className="container container-md">
              <div className="row">
                <DevelopmentServicesSectionItem
                  image={designWithUiOne}
                  title="Stand out from competitors with design"
                  description="The unique corporate identity of your web product helps clients to remember the company better. Thus, it stimulates further interaction. Corporate colors, fonts, and branded UX and UI design elements will help to increase brand trust and establish contact with customers."
                />
                <DevelopmentServicesSectionItem
                  image={designWithUiTwo}
                  title="Validate an idea with clickable prototypes"
                  description="Prototypes help showcase the web product and visualize in detail how your platform will work and how your customer will interact with it. With prototypes, UX/UI designers show the main pages of the future website and the client's path through them."
                />
                <DevelopmentServicesSectionItem
                  image={designWithUiThree}
                  title="Full-stack team of professionals"
                  description="With us, you get more than custom web design services. We will take care of all details of your web design, working together with project managers and developers. Our specialists can always clarify all the technical nuances and advise on the optimal solutions."
                />
              </div>
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="withoutDesign">
            <div className="container container-md">
              <div className="row">
                <DevelopmentServicesSectionItem
                  image={designWithoutUiOne}
                  title="Template design like everyone else"
                  description="In the absence of a custom approach, companies do not take into the interests of the target user. Your company will not stand out from competitors in any way and will be able to use only standard icons and buttons."
                />
                <DevelopmentServicesSectionItem
                  image={designWithoutUiTwo}
                  title="There is no way to understand the project fully"
                  description="Prototypes help to understand what features are needed for better user experience, set the correct estimate of the project, understand the technology stack and approve all communication scenarios with the user."
                />
                <DevelopmentServicesSectionItem
                  image={designWithoutUiThree}
                  title="Only the design services team"
                  description="We believe that the work of designers and developers together is much more efficient and productive than when these teams do not interact with each other. Daily project meetings, retrospectives, and discussions provide an opportunity to balance beautiful UI/UX design and clean code."
                />
              </div>
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  )
}

export default DevelopmentServicesSection
