import { graphql, useStaticQuery } from 'gatsby'

import { PhotosQuery } from '~/types/graphql'

const useDesignStaticQuery = () => {
  const query = useStaticQuery<PhotosQuery>(graphql`
    query {
      designWithUiOne: file(
        relativePath: { eq: "services/design/design-with-ui-1.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      designWithUiTwo: file(
        relativePath: { eq: "services/design/design-with-ui-2.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      designWithUiThree: file(
        relativePath: { eq: "services/design/design-with-ui-3.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      designWithoutUiOne: file(
        relativePath: { eq: "services/design/design-without-ui-1.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      designWithoutUiTwo: file(
        relativePath: { eq: "services/design/design-without-ui-2.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      designWithoutUiThree: file(
        relativePath: { eq: "services/design/design-without-ui-3.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      tailoredOne: file(
        relativePath: { eq: "company-photo/company-photo-33.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      tailoredTwo: file(
        relativePath: { eq: "company-photo/company-photo-47.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      portfolioPMK: file(
        relativePath: { eq: "homepage/discover-works-portfolio-2.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      portfolioMalleni: file(
        relativePath: { eq: "homepage/discover-works-portfolio-6.png" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      fullVideoImage: file(
        relativePath: { eq: "services/video/design-video.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }

      quoteCodicaInessaPhoto: file(relativePath: { eq: "avatars/Inessa.png" }) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
    }
  `)

  return query
}

export default useDesignStaticQuery
