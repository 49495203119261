import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

import { cx } from '~/utils'

import * as containerStyles from './DevelopmentServicesSectionItem.module.scss'

interface DevelopmentServicesSectionItemProps {
  image: IGatsbyImageData | undefined
  title: string
  description: string
}

const DevelopmentServicesSectionItem = ({
  image,
  title,
  description,
}: DevelopmentServicesSectionItemProps) => (
  <div
    className={cx(
      'col-12 col-md-6 col-lg-4',
      containerStyles.developmentServicesSection__container,
    )}
  >
    <div className={containerStyles.developmentServicesSection__card}>
      <div className={containerStyles.developmentServicesSection__image}>
        <GatsbyImage
          image={image as IGatsbyImageData}
          alt={`${title} | Codica`}
          title={title}
          loading="lazy"
        />
      </div>
      <div className={containerStyles.developmentServicesSection__cardWrapper}>
        <div className={containerStyles.developmentServicesSection__title}>
          {title}
        </div>
        <div
          className={containerStyles.developmentServicesSection__description}
        >
          {description}
        </div>
      </div>
    </div>
  </div>
)

export default DevelopmentServicesSectionItem
