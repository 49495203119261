import { SeoProps } from '~/components/shared/Seo'

type SchemaPersonParams = Pick<
  SeoProps,
  'title' | 'description' | 'url' | 'authorSlug' | 'authorName' | 'urlImage'
>

export const SchemaPerson = ({
  title,
  description,
  authorName,
  authorSlug,
  url,
  urlImage,
}: SchemaPersonParams) => {
  const schema = {
    '@context': 'https://schema.org',
    type: 'Person',
    id: `${url}#person-webpage`,
    name: title,
    description,
    sameAs: [`https://www.codica.com/blog/author/${authorSlug}/`],
    image: {
      type: 'ImageObject',
      id: 'https://codica.com/blog/#/schema/person/image/',
      inLanguage: 'en-US',
      url: `https://www.codica.com${urlImage}`,
      contentUrl: `https://www.codica.com${urlImage}`,
      caption: authorName,
    },
    mainEntityOfPage: {
      type: 'ProfilePage',
      id: `${url}#webpage`,
      url,
      name: title,
      description,
      inLanguage: 'en-US',
      isPartOf: {
        type: 'WebSite',
        id: 'https://codica.com/blog/#website',
        url: 'https://codica.com/blog/',
        name: 'Custom Software Development Blog by Codica',
        description: 'Blog on Custom Software Development',
        inLanguage: 'en-US',
        potentialAction: {
          type: 'SearchAction',
          target: {
            type: 'EntryPoint',
            urlTemplate: `https://codica.com/blog/?s={search_term_string}`,
          },
          'query-input': {
            type: 'PropertyValueSpecification',
            valueRequired: 'https://schema.org/True',
            valueName: 'search_term_string',
          },
        },
      },
      potentialAction: {
        type: 'ReadAction',
        target: {
          type: 'EntryPoint',
          urlTemplate: url,
        },
      },
    },
  }

  return JSON.stringify(schema)
}
