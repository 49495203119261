import React, { ReactNode } from 'react'

import { DataContentProps } from '~/types/helper'
import { cx } from '~/utils'

import * as containerStyles from './ServicesStepsProcess.module.scss'

export interface ServicesStepsProcessData {
  title: string
  image: React.JSX.Element
  className: string
  description: ReactNode
}

const ServicesStepsProcess = ({
  dataContent,
}: DataContentProps<ServicesStepsProcessData>) => (
  <div className="container container-md">
    <div className="row">
      {dataContent.map(({ className, image, title, description }) => (
        <div className="col-12" key={title}>
          <div className={containerStyles.stepsProcessSection__wrapper}>
            <div
              className={cx(
                containerStyles.stepsProcessSection__card,
                className,
              )}
            >
              <div className={containerStyles.stepsProcessSection__info}>
                <div className={containerStyles.stepsProcessSection__title}>
                  {title}
                </div>
                <div
                  className={containerStyles.stepsProcessSection__description}
                >
                  {description}
                </div>
              </div>
              <div className={containerStyles.stepsProcessSection__image}>
                {image}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
)

export default ServicesStepsProcess
