import { StaticImage } from 'gatsby-plugin-image'

import { CommonImageProps } from '~/types/common-props'
import * as containerStyles from '~/views/Services/components/ServicesStepsProcess/ServicesStepsProcess.module.scss'
import { ServicesStepsProcessData } from '~/views/Services/components/ServicesStepsProcess/ServicesStepsProcess.view'

const imageFirst =
  '../../../assets/images/services/design/design-steps-process-1.png'
const imageTwo =
  '../../../assets/images/services/design/design-steps-process-2.png'
const imageThree =
  '../../../assets/images/services/design/design-steps-process-3.png'
const imageFour =
  '../../../assets/images/services/design/design-steps-process-4.png'

const imageProps: CommonImageProps = {
  width: 727,
}

const DesignProcess: ServicesStepsProcessData[] = [
  {
    title: 'Research',
    description: (
      <>
        At this stage, the client shares with us their vision of the product
        they would like to see at the end of development and we study the
        subject area of the project. We learn who our users are and what the
        problems they face are.
        <div key="research" style={{ marginBottom: 16 }} />
        Also, we analyze similar web products. Based on the results, we offer
        implementation ideas. Our designers are always keeping up with the
        latest trends in web design.
      </>
    ),
    get image() {
      return (
        <StaticImage
          src={imageFirst}
          alt={`${this.title} | Codica`}
          title={this.title}
          {...imageProps}
        />
      )
    },
    className: containerStyles.stepsProcessSection__cardFirst,
  },
  {
    title: 'Prototyping',
    description: (
      <>
        Next, we move on to prototyping. Prototypes are created before the
        development of the visual part to focus attention on the project’s
        architecture without distraction by color and font.
        <div key="prototyping" style={{ marginBottom: 16 }} />
        Therefore, prototypes are not colored but gray-blue. We think over the
        logic of the future user roles and make a flow for each of them (user
        journey or flows). After that, we make clickable prototypes and
        demonstrate them to the client.
      </>
    ),

    get image() {
      return (
        <StaticImage
          src={imageTwo}
          alt={`${this.title} | Codica`}
          title={this.title}
          {...imageProps}
        />
      )
    },
    className: containerStyles.stepsProcessSection__cardReverce,
  },
  {
    title: 'Visualization',
    description: (
      <>
        We collect references for inspiration. We make a logo if the client does
        not have one and choose fonts and color schemes. So, we create several
        design options and approved the style of the project with the client.
        <div key="visualization" style={{ marginBottom: 16 }} />
        It is an obligatory part of our working process to discuss the outcomes
        with the client at every stage of web development.
      </>
    ),
    get image() {
      return (
        <StaticImage
          src={imageThree}
          alt={`${this.title} | Codica`}
          title={this.title}
          {...imageProps}
        />
      )
    },
    className: containerStyles.stepsProcessSection__cardThree,
  },
  {
    title: 'Design',
    description: (
      <>
        Based on the visual part, we draw up a guideline that details the
        heading sizes, font, button colors, and other elements of design. Thanks
        this guideline, developers get an idea of how the whole solution should
        look.
        <div key="design" style={{ marginBottom: 16 }} />
        Then, we design all pages, including mobile app and desktop versions.,
      </>
    ),
    get image() {
      return (
        <StaticImage
          src={imageFour}
          alt={`${this.title} | Codica`}
          title={this.title}
          {...imageProps}
        />
      )
    },
    className: containerStyles.stepsProcessSection__cardReverce,
  },
]

export default DesignProcess
