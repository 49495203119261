import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { useState } from 'react'

import * as containerStyles from './ServicesFullVideoSection.module.scss'

interface ServicesFullVideoSectionProps {
  videoLink: string
  videoImage: IGatsbyImageData | undefined
  videoTitle: string
}

const ServicesFullVideoSection = ({
  videoLink,
  videoImage,
  videoTitle,
}: ServicesFullVideoSectionProps) => {
  const [showVideo, setShowVideo] = useState(false)

  const handleChange = () => {
    setShowVideo(() => true)
  }

  return (
    <div className="container container-md">
      <div className="row">
        <div className="col-12">
          <div
            className={containerStyles.servicesFullVideoSection__clientImage}
          >
            {!showVideo && (
              <>
                <GatsbyImage
                  image={videoImage as IGatsbyImageData}
                  alt={`${videoTitle} | Codica`}
                  title={videoTitle}
                  loading="lazy"
                />
                <button
                  type="button"
                  name="Play video"
                  aria-label="Play video"
                  onClick={handleChange}
                  onKeyDown={handleChange}
                  className={
                    containerStyles.servicesFullVideoSection__playWrapper
                  }
                >
                  Play video
                </button>
              </>
            )}
            <div
              className={containerStyles.servicesFullVideoSection__videoYoutube}
            >
              {showVideo && (
                <iframe
                  src={videoLink}
                  title="Video case study"
                  width="100%"
                  height="100%"
                  allowFullScreen
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ServicesFullVideoSection
