import { FAQ } from '~/types/data-array'

const DesignFAQ: FAQ[] = [
  {
    question: 'How long does it take to design a custom UI/UX for a project?',
    answer:
      'Our main steps involved: studying the project vision and target users, creating clickable prototypes to test the user flows and interactions, establishing the visual style, and designing the UI for all pages and device sizes. Usually, this lasts 120 - 180 hours.',
  },
  {
    question: 'What is the cost range for UI/UX design services?',
    answer:
      'We provide free quotes depending on the project’s requirements. The estimate for design services is $4800 - $7200.',
  },
  {
    question: 'What benefits do you get with custom UI/UX design?',
    answer:
      'You’ll improve the customer experience to attract more clients and achieve business goals, stand out from competitors with a unique, branded design, validate ideas early with clickable prototypes and have a full-stack team of designers, developers, and project managers working together.',
  },
  {
    question:
      'How can you ensure your UI/UX follows the latest accessibility standards?',
    answer:
      'We follow the latest Web Content Accessibility Guidelines (WCAG) and other relevant accessibility standards.',
  },
]

export default DesignFAQ
