import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React from 'react'

import { Quote } from '~/types/data-array'
import { cx } from '~/utils'

import * as containerStyles from './TestimonialPost.module.scss'

export interface TestimonialPostProps {
  image: IGatsbyImageData | JSX.Element | undefined
  name: string
  position: string
  quote: Quote[]
  nameSm?: boolean
}

const TestimonialPost = ({
  quote,
  image,
  name,
  position,
  nameSm,
}: TestimonialPostProps) => (
  <div
    className={cx('container container-md', containerStyles.testimonialPost)}
  >
    <div className="row align-items-center">
      <div className="col-md-3">
        <div className={containerStyles.testimonialPost__infoBlock}>
          <div className={containerStyles.testimonialPost__imageWrapper}>
            {React.isValidElement(image) ? (
              image
            ) : (
              <GatsbyImage
                image={image as IGatsbyImageData}
                alt={`${name} ${position}`}
                title={`${name} ${position} at Codica`}
                loading="lazy"
              />
            )}
          </div>
          <div
            className={
              nameSm
                ? containerStyles.testimonialPost__infoNameSmall
                : containerStyles.testimonialPost__infoName
            }
          >
            {name}
          </div>
          <div className={containerStyles.testimonialPost__infoPosition}>
            {position}
          </div>
        </div>
      </div>
      <div className="col-md-9">
        {quote.map(({ text }) => (
          <p className={containerStyles.testimonialPost__infoQuote} key={text}>
            {text}
          </p>
        ))}
      </div>
    </div>
  </div>
)

export default TestimonialPost
