import { SeoProps } from '~/components/shared/Seo'

type SchemaBlogPostingParams = Pick<
  SeoProps,
  | 'description'
  | 'url'
  | 'headline'
  | 'datePublished'
  | 'dateModified'
  | 'author'
  | 'image'
>

export const SchemaBlogPosting = ({
  description,
  url,
  headline,
  datePublished,
  dateModified,
  author,
  image,
}: SchemaBlogPostingParams) => {
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'BlogPosting',
    mainEntityOfPage: url,
    headline,
    datePublished,
    dateModified,
    description,
    author,
    publisher: {
      '@type': 'Organization',
      name: 'Codica',
      logo: {
        '@type': 'ImageObject',
        url: 'https://codica-images-production.s3.eu-central-1.amazonaws.com/codica_logo_3e7a6581a4.png',
        width: 148,
        height: 31,
      },
    },
    image: {
      '@type': 'ImageObject',
      url: image,
      width: 1895,
      height: 660,
    },
  }

  return JSON.stringify(schema)
}
