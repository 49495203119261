import { Link } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'

import EmptyFallback from '~/components/render-kit/EmptyFallback'
import RenderWhenVisible from '~/components/render-kit/RenderWhenVisible'
import { AllStrapiArticle } from '~/types/graphql'
import { convertInDateWithoutTime, cx } from '~/utils'

import ArticleImage from './ArticleImage'
import * as containerStyles from './RecentArticles.module.scss'

interface RecentArticlesProps {
  data: AllStrapiArticle
  buttonText?: string
}

const RecentArticles = ({ data, buttonText }: RecentArticlesProps) => (
  <div className={containerStyles.recentPost}>
    <div className="container container-md">
      <RenderWhenVisible
        className="row"
        fallback={<EmptyFallback height={333} className="row" />}
      >
        {data.allStrapiArticle.edges.map(({ node: article }) => {
          const modifiedDate = convertInDateWithoutTime(
            article!.modified_date as string,
          )

          return (
            <div className="col-12 col-md-6 col-lg-4" key={article.url}>
              <div className={containerStyles.recentPost__postCard}>
                <Link
                  title={article.title?.trim() || ''}
                  to={`/blog/${article.url}/`}
                  className={containerStyles.recentPost__postLink}
                />
                <div className={containerStyles.recentPost__postImage}>
                  <ArticleImage
                    image={
                      article.preview_image!
                        .localFile as unknown as IGatsbyImageData
                    }
                    alt={article.preview_image_alt || ''}
                  />
                </div>

                <div className={containerStyles.recentPost__postInfo}>
                  <div
                    className={containerStyles.recentPost__postStats}
                  >{`${Math.ceil(
                    article?.body?.data?.childMarkdownRemark?.timeToRead || 0,
                  )} min`}</div>

                  <div className={containerStyles.recentPost__postDate}>
                    {modifiedDate}
                  </div>
                </div>

                <div
                  className={cx(
                    containerStyles.recentPost__postTitle,
                    containerStyles.recentPost__postClamp,
                  )}
                >
                  {article.title?.trim()}
                </div>

                <div
                  className={cx(
                    containerStyles.recentPost__postDescriprion,
                    containerStyles.recentPost__postClamp,
                  )}
                >
                  {article?.meta_description}
                </div>

                <div className={containerStyles.recentPost__postCategory}>
                  {article.category?.name}
                </div>
              </div>
            </div>
          )
        })}

        {buttonText && (
          <div className="col-12 d-flex justify-content-center mt-4">
            <Link to="/blog/" className={containerStyles.recentPost__button}>
              {buttonText}
            </Link>
          </div>
        )}
      </RenderWhenVisible>
    </div>
  </div>
)

export default RecentArticles
