export const SchemaOnlineBusiness = () => {
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'OnlineBusiness',
    url: 'https://www.codica.com',
    name: 'Codica OU',
    description:
      'Software Development Company of 60 IT Experts. High Quality Software Development',
    email: 'hello@codica.com',
    telephone: '+38 097 778 9031',
    keywords:
      'mobile app development, mobile app development company, ecommerce website design, mobile application development, custom software development company, mobile app development services, ecommerce web design, custom software development services, app store optimization, cross platform app development',
  }

  return JSON.stringify(schema)
}
