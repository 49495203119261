import { ReactNode } from 'react'

import { cx } from '~/utils'

import * as styles from './HeadSection.module.scss'

interface HeadSectionProps {
  title: string
  subtitle?: string
  description?: ReactNode
  position?: string
  className?: string
  headingLevel?: number
  titleLarge?: boolean
  titleFullWidth?: boolean
  descriptionLarge?: boolean
}

const HeadSection = ({
  title,
  subtitle,
  description,
  position,
  className,
  headingLevel = 2,
  titleLarge,
  titleFullWidth,
  descriptionLarge,
}: HeadSectionProps) => {
  const TitleComponent = (props) => {
    const HeadingTag = `h${headingLevel}`
    return <HeadingTag {...props}>{props.children}</HeadingTag>
  }

  return (
    <div className="container container-md">
      <div className="row">
        <div className="col-12">
          <div
            className={cx(
              position && styles[`headerSection__${position}`],
              className,
            )}
          >
            {subtitle && (
              <div className={styles.headerSection__subtitle}>{subtitle}</div>
            )}

            <TitleComponent
              className={cx(
                styles.headerSection__title,
                titleLarge && styles.headerSection__titleLarge,
                titleFullWidth && styles.headerSection__titleFullWidth,
              )}
            >
              {title}
            </TitleComponent>

            {description && (
              <p
                className={cx(
                  styles.headerSection__description,
                  descriptionLarge && styles.headerSection__descriptionLarge,
                )}
              >
                {description}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeadSection
